import { syncOfflineStaffChanges } from "./services/staffService";
import { syncOfflineRolesChanges } from "./services/rolesService";
import { syncOfflineVehiclesChanges } from "./services/vehiclesService";
import { syncOfflineProjectsChanges } from "@/services/projectsService";
import { syncOfflineVehicleRunningChanges } from "@/services/vehicleRunningService";
import { syncOfflineVehicleRepairChanges } from "@/services/vehicleRepairsService";
import { syncOfflineVehicleFuelChanges } from "@/services/vehicleFuelService";
import { syncAuth } from "./services/authService";

export const syncDB = async () => {
  await syncOfflineStaffChanges();
  await syncOfflineRolesChanges();
  await syncOfflineVehiclesChanges();
  await syncOfflineProjectsChanges();
  await syncOfflineVehicleRunningChanges();
  await syncOfflineVehicleRepairChanges();
  await syncOfflineVehicleFuelChanges();
  await syncAuth();
};
