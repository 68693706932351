export function reset() {
  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear IndexedDB
  if (window.indexedDB && window.indexedDB.databases) {
    window.indexedDB
      .databases()
      .then((databases) => {
        databases.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });
      })
      .catch((err) => console.error("Error clearing IndexedDB:", err));
  }

  // Clear Cache Storage
  if ("caches" in window) {
    caches
      .keys()
      .then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      })
      .catch((err) => console.error("Error clearing caches:", err));
  }

  // Reload the page
  window.location.reload(true);
}
