<template background-color="bg">
  <v-app class="back">
    <div class="d-flex align-center px-5 py-3">
      <v-img max-width="20" alt="IMSO" src="/images/Icon.png" />
      <div class="text-h6 font-weight-bold primary--text">IMSO</div>
    </div>

    <v-main>
      <router-view />
      <v-snackbar
        top
        right
        v-model="isMessage"
        timeout="5000"
        :vertical="error.isError"
        color="snackBar"
      >
        <v-icon class="me-2" color="error" v-if="error.isError">
          mdi-alert-circle-outline
        </v-icon>
        <v-icon class="me-2" color="primary" v-else>
          mdi-check-circle-outline
        </v-icon>
        {{ error.error }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="error.isError ? 'error' : 'primary'"
            text
            v-bind="attrs"
            @click="isMessage = false"
          >
            {{ error.isError ? "Close" : "OK" }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <div class="px-5 font-weight-light text-caption grey--text py-2 text-right">
      © 2024, OPEnE. All rights reserved. IMSO version Beta {{ $version }}
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    error: (state) => state.error.data,
    settings: (state) => state.settings.data,
  }),
  components: {
    //
  },
  data: () => ({
    isMessage: false,
    isDark: false,
  }),
  watch: {
    error(newError) {
      if (newError) {
        this.isMessage = true;
      }
    },
    isMessage(newValue) {
      if (newValue === false) {
        this.$store.dispatch("resetError");
      }
    },
  },
  created() {
    if (this.settings) {
      this.isDark = this.settings.isDark;
      this.$vuetify.theme.dark = this.settings.isDark;
    } else {
      this.isDark = this.$vuetify.theme.dark;
      this.$store.commit("updateSettings", {
        isDark: this.isDark,
      });
    }

    if (this.isDark) {
      document.documentElement.style.setProperty("--scrollbar-bg", "#22272e");
      document.documentElement.style.setProperty(
        "--scrollbar-thumb",
        "#2d333b"
      );
      document.documentElement.style.setProperty(
        "--scrollbar-border",
        "#ffffff31"
      );
    } else {
      document.documentElement.style.setProperty("--scrollbar-bg", "#f4f4f4");
      document.documentElement.style.setProperty(
        "--scrollbar-thumb",
        "#bdbdbd"
      );
      document.documentElement.style.setProperty(
        "--scrollbar-border",
        "#d7d7d7"
      );
    }
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
.back {
  background-color: rgba(240, 243, 246, 0.411) !important;
}
</style>